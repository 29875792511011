<template>
  <a-layout class='index animated fadeIn'>
    <a-card>
      <a-form ref='form' :model='search' :style="{'marginBottom':'20px'}" :initialValues="search" :onFinish='pageSearchChange' layout='inline'>
        <a-form-item label="关键词" name='brand'>
          <a-input v-model:value="search.brand" :style="{width:'250px'}" placeholder="请输入品牌商名称/编号/账号"></a-input>
        </a-form-item>
        <a-form-item label="审核状态" name='apply_status'>
          <a-select v-model:value="search.apply_status" :style="{width:'180px'}" placeholder="全部">
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option :value="1">
              待处理
            </a-select-option>
            <a-select-option :value="2">
              已通过
            </a-select-option>
            <a-select-option :value="3">
              已拒绝
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="提交时间" name='time'>
          <a-range-picker v-model:value="time" valueFormat='YYYY-MM-DD' format='YYYY-MM-DD' @change="onChange" />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" htmlType='submit'>搜索</a-button>
        </a-form-item>
      </a-form>
      <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns' :data-source='data' class="tableFixBox" :scroll="{ x: 'max-content'}">

        <template v-slot:status_text="{ record }">
          <span :style="{color:record.status==1?'#f04635':record.status==2?'#333':'#999'}">{{record.status_text}}</span>
        </template>
        <template v-slot:action="{ record }">
          <a-popover placement="top">
            <template v-slot:content>
              <p>{{record.audit_reason|| '-'}}</p>
            </template>
            <template v-slot:title>
              <span>原因</span>
            </template>
            <a-button type="primary" size="small" ghost>原因</a-button>
          </a-popover>
          <a-button type="primary" size="small" class="m-l-20" v-if="record.status == 1" v-has:brandFreeReview="()=>brandFreeReview(record)" >审核</a-button>
        </template>
      </a-table>
      <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper :current="search.page" :page-size="search.limit" :total="total" :show-total="total => `总数: ${total} `" @change="pageCurrentChange" @showSizeChange="pageSizeChange" />
    </a-card>
    <a-modal v-model:visible="visible" title="品牌商付费模式修改审核" @ok="handleOk">
      <a-radio-group v-model:value="valueRadio" @change="onRadioChange">
        <a-radio :value="2">
          通过
        </a-radio>
        <a-radio :value="3">
          拒绝
        </a-radio>
      </a-radio-group>
      <a-textarea v-if='valueRadio == 3' class="mt15" v-model:value="audit_reason" placeholder="请输入拒绝的原因" auto-size />
    </a-modal>
  </a-layout>
</template>
<script>
import { ref, onMounted, reactive, toRefs, inject } from "vue";
import { message } from "ant-design-vue";
import { $iscode } from "@/utils/app";
import { systemFeesTypeApplyLists, auditFeesTypeApply } from "@/api/brand";
// 初始化默认筛选项数值
let defSearch = {
  brand: "",
  apply_status: "",
  page: 1,
  limit: 10,
  start_time: "",
  end_time: "",
};
export default {
  components: {},
  setup(props) {
    // // 初始化 分页信息和筛选项信息
    let search = ref({ ...defSearch });
    // 列表数据和列头配置
    let data = ref([]);
    const state = reactive({
      time: [],
      listLoading: false,
      visible: false,
      total: 1,
      valueRadio: null,
      audit_reason: "",
      brandItem: {},
    });
    let columns = [
      {
        title: "品牌商编号",
        dataIndex: "brand_no",
        key: "brand_no",
      },
      {
        title: "品牌商账号",
        dataIndex: "account",
        key: "account",
      },
      {
        title: "公司名称",
        dataIndex: "company",
        key: "company",
      },
      {
        title: "变更前有效期",
        dataIndex: "signing_time_before",
        key: "signing_time_before",
      },
      {
        title: "变更后有效期",
        dataIndex: "signing_time_after",
        key: "signing_time_after",
      },
      {
        title: "变更前剩余时间(天)",
        dataIndex: "remain_day",
        key: "remain_day",
      },
      {
        title: "审核状态",
        dataIndex: "status_text",
        key: "status_text",
        slots: { customRender: "status_text" },
      },
      {
        title: "操作",
        key: "action",
        fixed:"right",
        slots: { customRender: "action" },
      },
    ];
    // 页面筛选项搜索
    const pageSearchChange = () => {
      search.value = { ...search.value, page: 1 };
      initData(search.value);
    };
    // 分页当前页切换
    const pageCurrentChange = (page, pageSize) => {
      search.value.page = page;
      initData(search.value);
    };
    // 分页当前页显示多少条切换
    const pageSizeChange = (current, size) => {
      search.value.page = 1;
      search.value.limit = size;
      initData(search.value);
    };
    //选择时间
    const onChange = (e) => {
      search.value.start_time = e[0];
      search.value.end_time = e[1];
    };
    //打开审核弹窗
    const brandFreeReview = (e) => {
      state.visible = true;
      state.brandItem = e;
    };
    // 选择审核
    const onRadioChange = (e) => {
      state.valueRadio = e.target.value;
    };
    //确认审核
    const handleOk = async () => {
      if (state.valueRadio == null) {
        message.error("请选择是否通过审核！");
        return false;
      }
      let res = await auditFeesTypeApply({
        id: state.brandItem.id,
        apply_status: state.valueRadio,
        audit_reason: state.audit_reason,
      }).then((res) => res.data);
      if ($iscode(res)) {
        state.visible = false;
        message.success(res.msg);
        initData(search.value);
      } else {
        message.error(res.message);
      }
    };
    //获取列表
    const initData = async (values) => {
      state.listLoading = true;
      try {
        let res = await systemFeesTypeApplyLists(values).then(
          (res) => res.data
        );
        state.listLoading = false;
        if ($iscode(res)) {
          data.value = res.data.data;
          state.total = res.data.total;
        } else {
          message.error(res.message);
        }
      } catch (e) {
        state.listLoading = false;
      }
    };
    onMounted(() => {
      initData(search.value);
    });
    return {
      ...toRefs(state),
      search,
      data,
      columns,
      pageSearchChange,
      pageCurrentChange,
      pageSizeChange,
      onChange,
      handleOk,
      onRadioChange,
      brandFreeReview,
    };
  },
};
</script>